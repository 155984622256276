<template>
  <v-container>
    <breadcrumbs class="mb-4" :items="breadcrumbs"></breadcrumbs>
    <placeholder></placeholder>
  </v-container>
</template>
<script>
import Placeholder from "@/components/Placeholder.vue";
import Breadcrumbs from "@/components/Breadcrumbs.vue";

export default {
  name: "medical",
  components: {
    Placeholder,
    Breadcrumbs
  },
  data: () => ({}),
  computed: {
    breadcrumbs: function() {
      return [
        {
          text: this.$t("home"),
          disabled: false,
          href: "/"
        },
        {
          text: this.$t("help"),
          disabled: false,
          href: "/help"
        },
        {
          text: this.$t("medical"),
          disabled: true
        }
      ];
    }
  }
};
</script>
